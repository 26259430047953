var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modules--swap--components--swap-providers-list my-5" },
    [
      _vm.step >= 1 && _vm.toTokenSymbol && !_vm.hasProviderError
        ? _c(
            "v-item-group",
            { attrs: { value: 0 } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _vm.providersList.length > 0
                    ? _c("div", { staticClass: "mew-heading-3 mb-5 pl-4" }, [
                        _vm._v(" Select rate "),
                      ])
                    : _vm._e(),
                  _vm._l(_vm.providersList, function (quote, idx) {
                    return _c(
                      "v-col",
                      {
                        key: "quote-" + idx,
                        staticClass: "mb-1",
                        attrs: { cols: "12" },
                      },
                      [
                        _c("v-item", {
                          ref: "card" + idx,
                          refInFor: true,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var active = ref.active
                                  var toggle = ref.toggle
                                  return [
                                    _c(
                                      "v-container",
                                      {
                                        class: [
                                          active ? "rate-active" : "",
                                          "d-flex align-center rate py-0 px-1",
                                        ],
                                        on: {
                                          click: function ($event) {
                                            toggle()
                                            _vm.setProvider(idx, true)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "align-center justify-start",
                                            attrs: { "no-gutters": "" },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                attrs: { cols: "10", sm: "7" },
                                              },
                                              [
                                                _c(
                                                  "v-container",
                                                  { staticClass: "pa-2" },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      {
                                                        staticClass:
                                                          "align-center justify-start pl-5 pr-1 py-3 py-sm-4",
                                                      },
                                                      [
                                                        _c(
                                                          "mew-token-container",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                              img: _vm.toTokenIcon,
                                                            },
                                                          }
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-block d-sm-flex mx-2 mx-sm-4 align-center justify-start",
                                                          },
                                                          [
                                                            _vm.bestRate !==
                                                              null &&
                                                            _vm.bestRate ===
                                                              quote.rate
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "greenPrimary--text font-weight-medium mew-label order-sm-12 pl-sm-2",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " Best Rate "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex order-sm-1 justify-start align-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0 mew-heading-3 font-weight-medium",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          quote.amount
                                                                        ) +
                                                                        " " +
                                                                        _vm._s(
                                                                          _vm.toTokenSymbol
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                quote.amount &&
                                                                quote.amount !==
                                                                  ""
                                                                  ? _c(
                                                                      "mew-tooltip",
                                                                      {
                                                                        staticClass:
                                                                          "pl-1",
                                                                        attrs: {
                                                                          text: quote.tooltip,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2", sm: "5" } },
                                              [
                                                _c(
                                                  "v-row",
                                                  {
                                                    staticClass:
                                                      "align-center justify-end pr-3",
                                                  },
                                                  [
                                                    _c("mew-checkbox", {
                                                      attrs: { value: active },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step >= 1 &&
      _vm.providersCut > 0 &&
      _vm.toTokenSymbol &&
      !_vm.hasProviderError
        ? _c(
            "div",
            {
              staticClass:
                "cursor--pointer user-select--none greenPrimary--text mt-7 ml-4",
              on: {
                click: function ($event) {
                  _vm.showMore = !_vm.showMore
                },
              },
            },
            [
              _vm._v(" " + _vm._s(_vm.moreProvidersText) + " "),
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showMore,
                      expression: "!showMore",
                    },
                  ],
                  attrs: { small: "", color: "greenPrimary" },
                },
                [_vm._v("mdi-arrow-down")]
              ),
              _c(
                "v-icon",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMore,
                      expression: "showMore",
                    },
                  ],
                  attrs: { small: "", color: "greenPrimary" },
                },
                [_vm._v("mdi-arrow-up")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.step >= 1 && _vm.hasProviderError && !_vm.isLoading
        ? _c("app-user-msg-block", {
            attrs: {
              message: _vm.providersError,
              "is-alert": false,
              "container-padding": "pa-4 py-6",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }